<template>
  <div class="container">
    <b-overlay
      variant="white"
      rounded="sm"
    >
      <b-card class="p-1">

        <b-tabs
          id="profile"
          fill
          vertical
          nav-wrapper-class="nav-vertical"
        >

          <b-tab
            v-if="user_group.account_type === this.$AccountTypes.APPLICANT"
          >
            <template #title>
              <feather-icon icon="MailIcon" />
              <span>Communication Preferences</span>
            </template>

            <div class="p-1">
              <b-row>
                <b-form-checkbox
                  v-model="mail_configuration.job_alerts"
                  class="ml-6"
                >Do you want to receive job alerts?</b-form-checkbox>
              </b-row>
              <!-- <div class="vx-row my-6 ml-2">
                <b-form-checkbox class="ml-6" v-model="mail_configuration.promotions"
                  >Do you want to receive offers and promotions from HeadHunters
                  HQ?</b-form-checkbox
                >
              </div> -->
              <b-row>

                <ButtonSpinner
                  :is-loading="isLoading"
                >
                  <b-button
                    variant="primary"
                    class="mt-1"
                    @click="updateConfigInfo"
                  >
                    Save Changes
                  </b-button>
                </ButtonSpinner>
              </b-row>
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="EyeOffIcon" />
              <span>Privacy Preferences</span>
            </template>

            <div class="p-1">
              <div class="d-flex flex-column border"> 
                <h6
                  class="ml-6 font-weight-bolder"
                  style="font-weight: 500;"
                >
                  As we understand the importance of your privacy, you may opt in
                  for the following options:
                </h6>
                <div class="mt-1 pl-1">
                  <b-form-checkbox
                    v-model="privacy_configuration.mask_email"
                    class="my-1"
                  >Receive Marketing/Outreach Emails</b-form-checkbox>
                  <b-alert
                    variant="primary"
                    show
                  >
                    <div class="alert-body">
                      <b>Note:</b> Do note that Transactional emails that are required for us to continue providing the service to you will still be opt-in
                    </div>
                  </b-alert>
                </div>

                <div class="mt-3 float-right">
                  <ButtonSpinner :is-loading="isLoading">
                    <b-button
                      class="mt-3 float-right b-button-size-md"
                      variant="primary"
                      @click="updateConfigInfo"
                    >
                      Save Changes
                    </b-button>
                  </ButtonSpinner>
                </div>
              </div>

            </div>
          </b-tab>

          <b-tab
            title="Manage Your Data"
            icon-pack="feather"
            icon="icon-folder"
          >
            <template #title>
              <feather-icon icon="FolderIcon" />
              <span>Manage Your Data</span>
            </template>

            <div class="p-1">
              <b-alert
                class="mb-2"
                variant="danger"
                show
              >
                <div class="alert-body">
                  <b>Warning: </b> The actions below have the potential to permanently delete your data. Proceed only if you are certain.
                </div>
              </b-alert>

              <div class="d-flex flex-column justify-content-start flex-gap-y-md">
                <div>
                  <ButtonSpinner :is-loading="isLoading">
                    <b-button
                      class="b-button-size-lg"
                      variant="warning"
                      @click="deactivateAccount"
                    >
                      Deactivate Account for 1 Year
                    </b-button>
                  </ButtonSpinner>
                </div>
                <!-- <div
                  v-if="
                    $store.state.auth.ActiveUser.account_type === $AccountTypes.APPLICANT
                  "
                > -->
                <div>
                  <ButtonSpinner :is-loading="isLoading">
                    <b-button
                      class="b-button-size-lg"
                      variant="danger"
                      @click="deleteAccount"
                    >
                      Delete Account (Immediate)
                    </b-button>
                  </ButtonSpinner>
                </div>

              </div>
            </div>
          </b-tab>
        </b-tabs>

      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  VBTooltip,
  BOverlay,
  BFormCheckbox,
  BAlert,
  BTab,
  BTabs,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTab,
    BTabs,
    BButton,
    BRow,
    BCol,
    BOverlay,
    BFormCheckbox,
    BAlert,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      activeTab: 0,
      mail_configuration: {
        job_alerts: false,
        promotions: false,
      },
      privacy_configuration: {
        mask_email: false,
        mask_phone_number: false,
      },
      user_group: [],
      isLoading: false,
    }
  },
  watch: {
  },
  created() {
    const { token } = this.$store.state.auth.ActiveUser || {}
    this.$http.defaults.headers.common.Authorization = `Token ${token}`
    this.getConfigInfo()
    this.user_group = this.$store.state.auth.ActiveUser.user_group
  },
  methods: {
    updateConfigInfo() {
      this.isLoading = true
      const all_data = {
        privacy_configuration: this.privacy_configuration,
        mail_configuration: this.mail_configuration,
      }
      this.$http
        .put('/api/account-config', all_data)
        .then(response => {
          const { config, success } = response.data

          if (success) {
            this.mail_configuration = config.mail_configuration
            this.privacy_configuration = config.privacy_configuration

            this.$toastSuccess('Account Information', 'Account settings updated')
          }
          this.isLoading = false
        })
        .catch(error => {
          this.$toastSuccess('Operation Failed', 'Settings not updated')
          this.isLoading = false
        })
    },

    getConfigInfo() {
      this.isLoading = true
      this.$http
        .get('/api/account-config')
        .then(response => {
          const { config } = response.data
          if (this.$isNotEmpty(config.mail_configuration)) {
            this.mail_configuration = config.mail_configuration
          }
          if (this.$isNotEmpty(config.privacy_configuration)) {
            this.privacy_configuration = config.privacy_configuration
          }
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
        })
    },

    deactivateAccount() {
      this.$simpleConfirmModal(this, 'Are you sure you want to deactivate the account for 1 year?', { title: 'Confirm Deactivate Account', okVariant: 'danger' })
        .then(response => {
          this.isLoading = true
          this.$http
            .delete('/api/deactivate-user-account')

            .then(resp => {
              const { output, success } = resp.data

              if (success) {
                this.$toastSuccess('Account Information', 'Account has been deactivated for 1 year')
                this.$logout(this, null)
              }
              this.isLoading = false
            })
            .catch(err => {
              this.isLoading = false
            })
        })
        .catch(error => {
          this.isLoading = false
        })
    },

    deleteAccount() {
      this.$simpleConfirmModal(this, 'Are you sure you want to delete this account permanently?', { title: 'Confirm Delete Account', okVariant: 'danger' })
        .then(response => {
          this.isLoading = true
          this.$http
            .delete('/api/delete-user-account')
            .then(resp => {
              const { success } = resp.data

              if (success) {
                this.$toastSuccess('Account Operation', 'Account has been deleted successfully')
                this.$logout(this, null)
              }
              this.isLoading = false
            })
            .catch(() => {
              this.isLoading = false
            })

          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
